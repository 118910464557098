import React from "react";
import styles from "./App.module.scss";

function App() {
    return (
        <div className={styles.app}>
            <header className="App-header">Hier app</header>
        </div>
    );
}

export default App;
